.container {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}