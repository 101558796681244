.footerContainer {
  margin-top: 20px;
}
.sponsorImage {
  width: 100%;
}
.sponsorButton {
  border-radius: 5px;
  background-image: linear-gradient(0deg, #f15a22 0%, #fff200 100%);
  border: 2px solid #fff200;
  font-size: 1.5rem;
  color: #910000;
  font-weight: bold;
  font-family: "Arial Rounded MT Bold";
  text-align: center;
  cursor: pointer;
}
.sponsorCredit {
  margin-top: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.sponsorCreditBot {
  padding: 10px;
  text-align: center;
}