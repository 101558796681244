.winnerSlot {
  border-radius: 10px;
  background-image: linear-gradient(0deg, #780000 0%, #be0000 100%);
  display: flex;
  overflow: hidden;
  position: relative;
}
.winnerSlotName {
  flex-basis: 75%;
  background-image: linear-gradient(0deg, #f15a22 0%, #ffcb05 100%);
  padding: 2px;
  font-weight: bold;
  font-size: 1.4rem;
  padding-left: 15px;
}
.winnerSlotNumber {
  flex-basis: 25%;
  display: flex;
  justify-content: flex-end;
  color: white;
  font-weight: bold;
  align-items: center;
  padding-right: 5px;
  font-size: 1.3rem;
}
.winnerSlotStart {
  position: absolute;
  width: 35px;
  height: 35px;
  left: 75%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.barPrize {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: flex-end;
  position: relative;
}
.prizePosImg {
  position: absolute;
  transform: translate(-50%, -50%);
  max-width: 150px;
  height: 150px;
}
.prizePosText {
  position: absolute;
  transform: translate(-50%, -50%);
  max-width: 170px;
}
.prizeTextBox {
  border-radius: 10px;
  background-image: linear-gradient(0deg, #910000 0%, #be0000 100%);
  border: 2px solid #ff0000;
  display: flex;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  width: 130px;
  height: 25px;
  align-items: center;
}
.prizePosWinner {
  position: absolute;
  transform: translate(-50%, -50%);
}
.SpesialWinnerSlot {
  border-radius: 10px;
  display: flex;
  background-image: linear-gradient(0deg, #f15a22 0%, #ffcb05 100%);
  align-items: center;
  overflow: hidden;
  height: 30px;
}
.spesialWinnerSlotName {
  width: 80%;
  color: #000000;
  font-weight: bold;
  text-align: center;
  padding-left: 5px;
  font-size: 1.3rem;
  z-index: 99999;
}
.spesialWinnerSlotNumber {
  background-image: linear-gradient(0deg, #780000 0%, #be0000 100%);
  width: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 8px;
  height: 100%;
  color: white;
  font-weight: bold;
  font-size: 1.3rem;
}
.spesialWinnerSlotStart {
  position: absolute;
  width: 28px;
  height: 28px;
  left: 80%;
  top: 50%;
  transform: translate(-50%, -50%);
}