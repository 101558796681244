.containerCover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-left: 3vw;
  padding-right: 3vw;
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.logoHeader {
  width: 100%;
}
.hariRayaWinnerSlot {
  background-image: linear-gradient(0deg, #780000 0%, #be0000 100%);
  display: flex;
  overflow: hidden;
  position: relative;
  margin-block: 5px;
  border-radius: 7px;
}
.hariWinnerSlotName {
  flex-basis: 75%;
  background-image: linear-gradient(0deg, #f15a22 0%, #ffcb05 100%);
  padding: 5px;
  font-weight: bold;
  font-size: 1.3rem;
}
.winnerSlotNumber {
  flex-basis: 25%;
  display: flex;
  justify-content: flex-end;
  color: white;
  font-weight: bold;
  align-items: center;
  padding-right: 5px;
  font-size: 1.3rem;
}
.winnerSlotStart {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 75%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.headerWinner {
  background-image: linear-gradient(0deg, #910000 0%, #be0000 100%);
  padding: 5px;
  border-radius: 10px;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  margin-bottom: 10px;
}
