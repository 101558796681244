.slotContainer {
  display: flex;
  justify-content: center;
}
.slotContainer > .wraper {
  width: 400px;
}
.slotContainer > .wraper > .slot {
  width: 100%;
  padding: 10px;
  background-color: #910000;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f7f410;
  font-weight: bold;
  font-size: 2rem;
}
.slotContainer > .wraper > .buttons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.ubo-button {
  height: 30px;
  display: flex;
  align-items: center;
  color: #640000;
  font-weight: bold;
  background: linear-gradient(0deg, #fff200 0%, #f15a22 100%);
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.animateFlicker {
  -webkit-animation: flickerAnimation 1s infinite;
  -moz-animation: flickerAnimation 1s infinite;
  -o-animation: flickerAnimation 1s infinite;
  animation: flickerAnimation 1s infinite;
}

@media only screen and (max-width: 1400px) {
  .slotContainer > .wraper {
    width: 300px;
  }
  .slotContainer > .wraper > .slot {
    padding: 5px;
    font-size: 2rem;
  }
}
