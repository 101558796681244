.tes{
  width: 45vw;
}
.imageFull {
  width: 100%;
  height: 100%;
}
.headerContainer {
  flex: 1;
  background-size: contain;
  background-repeat: no-repeat;
  justify-content: center;
  width: 50vw;
}
.person {
  height: 13rem;
  width: 17rem;
  position: absolute;
  top: 0%;
}
.money {
  position: absolute;
  top: 37%;
  left: 12.5%;
  height: 2rem;
  width: 2rem;
  transform: translate(-50%, -50%);
}
.undianText {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.idr {
  position: absolute;
  top: 28%;
  left: 64.7%;
  height: 120px;
  width: 600px;
  transform: translate(-50%, -50%);
}
.slotContainer {
  display: flex;
  justify-content: center;
}
.slotContainer > .wraper {
  width: 350px;
}
.live {
  color: white;
  padding: 4px;
  border: 1px solid red;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.liveDot {
  width: 15px;
  height: 15px;
  background-color: red;
  border-radius: 100%;
  margin-right: 7px;
}
.periodeText {
  color: white;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 10px;
  font-weight: bold;
  font-size: 1.7rem;
}
.slotContainer > .wraper > .slot {
  width: 100%;
  padding: 10px;
  background-color: red;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: yellow;
  font-weight: bold;
  font-size: 2rem;
}
.slotContainer > .wraper > .buttons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.button {
  padding: 1.5px;
  background-color: yellow;
  border-radius: 5px;
  width: 120px;
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 1.7rem;
  cursor: pointer;
}
.winnerSlotContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  color: white;
  direction: rtl;
}
.videoContainer {
  display: flex;
  align-items: flex-end;
  height: 250px;
  margin-top: 20px;
  margin-right: 20px;
}
.videoContainer > .videoFrame {
  height: 100%;
  width: 100%;
}
.videoContainer > .videoFrame > iframe {
  height: 100%;
  width: 100%;
}
.slotBox {
  height: 10%;
  width: 33%;
  padding-right: 5px;
}

@media only screen and (min-width: 1400px) {
  .winnerSlotContainer {
    height: 80vh;
  }
}

@media only screen and (max-width: 1400px) {
  .slotContainer > .wraper {
    width: 300px;
  }
  .live {
    font-size: 1rem;
  }
  .slotContainer > .wraper > .slot {
    padding: 5px;
    font-size: 2rem;
  }
  .liveDot {
    width: 10px;
    height: 10px;
  }
  .periodeText {
    font-size: 1.3rem;
  }
  .button {
    padding: 1px;
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 800px) {
  .tes {
    width: 100%;
    margin-bottom: 20px;
  }
  .headerContainer {
    width: 100%;
  }
  .slotContainer {
    margin-bottom: 20px;
  }
  .videoContainer {
    margin-bottom: 20px;
    min-height: 250px;
  }
  .winnerSlotContainer {
    margin-top: 20px;
    flex-direction: row;
    justify-content: flex-start;
    overflow-y: scroll;
  }
  .slotBox {
    width: 100% !important;
  }
}
