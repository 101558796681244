.containerBulananWinner {
  display: flex;
  position: relative;
  border-radius: 5px;
  border-radius: 10px;
  background-image: linear-gradient(0deg, #640000 0%, #be0000 100%);
  width: 200px;
  overflow: hidden;
  margin-block: 10px;
}
.bulananText {
  color: white;
  width: 150px;
  padding: 3px;
  padding-left: 10px;
  display: flex;
  font-size: 1.3rem;
  align-items: center;
  font-weight: bold;
}
.bulananNumber {
  background-image: linear-gradient(0deg, #f15a22 0%, #fff200 100%);
  padding: 3px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: #910000;
  font-weight: bold;
}
.bulananStar {
  position: absolute;
  transform: translate(-50%,-50%);
  left: 75%;
  top: 50%;
}