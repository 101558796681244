.containerCoverSpin {
  background-color: red;
}
.canvasContainer {
  position: relative;
}
.ring {
  position: absolute;
  transform: translate(-50%, -50%) rotate(0deg);
  left: 50%;
  top: 48%;
  width: 550px;
}
.pin {
  position: absolute;
  width: 70px;
  transform: translate(-50%, -50%);
  top: 48%;
  left: 1%;
}
.ball {
  position: absolute;
  width: 105px;
  transform: translate(-50%, -50%);
  top: 49%;
  left: 50%;
}
.containerWinner {
  flex-basis: 50%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 500px;
  margin-left: 100px;
  justify-content: space-between;
}
.headerWinnerSpin {
  display: flex;
  justify-content: center;
  font-size: 2rem;
  color: white;
  flex-basis: 49%;
  font-weight: bold;
  margin-bottom: 10px;
}
.itemWinnerSpin {
  flex-basis: 49%;
  color: white;
  border-radius: 10px;
  background-image: linear-gradient(0deg, #640000 0%, #be0000 100%);
  margin-bottom: 15px;
  font-size: 1.5rem;
  flex-direction: row;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  height: 42px;
}
.itemWinnerSpinNumber {
  min-width: 60px;
  max-width: 60px;
  background-image: linear-gradient(0deg, #f15a22 0%, #fff200 100%);
  color: #910000;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
}
.itemWinnerSpinName {
  flex-basis: 70%;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  padding-left: 18px;
}
.itemWinnerSpinStar {
  width: 35px;
  height: 35px;
  position: absolute;
  transform: translate(-50%,-50%);
  left: 25%;
  top: 50%;
}
.buttonsContainerSpin {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}