.topBar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.topBar > .logo {
  flex-basis: 20%;
}
.topBarText {
  font-weight: bold;
  color: white;
  font-size: 1.5rem;
  z-index: 2;
}
.marquee {
  height: 25px;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.marquee div {
  display: block;
  width: 200%;
  height: 30px;
  position: absolute;
  overflow: hidden;
  animation: marquee 7s linear infinite;
}

.marquee span {
  float: left;
  width: 50%;
}

@keyframes marquee {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

@media only screen and (max-width: 800px) {
  .logo {
    left: 32%;
  }
  .imageContentHeader {
    width: 250px;
  }
  .topBarText {
    font-size: 1.3rem;
    text-align: center;
    padding-top: 45px;
  }
}
